html, body, div#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.DayListMenu {
  /* box-shadow: 0px -2px 4px 2px rgba(0, 0, 0, 0.2); */
  background: white;
  /* background: linear-gradient(
    to top,
    white 92%,
    rgba(255, 0, 0, 0)
  ) */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
